<template>
  <div class="profile-page-wrap" v-show="isLoaded">
    <div class="profile-complete">
      <h1>Yay! You're all set</h1>
      <h2>Your portfolio is ready and available on the following link:</h2>
      <div class="portfolio-url">
        <div class="input-wrap">
          https://glims.io/{{ this.folio.sub_domain }}
        </div>
        <a href="https://glims.io/sanal" class="view-btn">View Portfolio </a>
      </div>
    </div>

    <div class="portfolio-share">
      <div class="share-content">
        <div class="share-icon">
          <img src="@/assets/images/icon-share.svg" alt="icon" />
        </div>
        <h3>Share it with the world</h3>
        <div class="share-url">
          https://glims.io/{{ this.folio.sub_domain }}
        </div>
      </div>

      <div class="share-buttons-wrap">
        <a href="#" class="share-btn">
          <img src="@/assets/images/icon-copy.svg" alt="icon" />
          <span>Copy link</span></a
        >
        <a href="#" class="share-btn btn-mail">
          <img src="@/assets/images/icon-mail.svg" alt="icon" />
          <span>Mail</span></a
        >
        <a href="#" class="share-btn btn-fb">
          <img src="@/assets/images/icon-fb.svg" alt="icon" />
          <span>Share</span>
        </a>
        <a href="#" class="share-btn btn-twitter">
          <img src="@/assets/images/icon-twitter.svg" alt="icon" />
          <span>Tweet</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;

export default {
  name: "ProfileReady",
  data() {
    return {
      username: this.$route.query.username,
      folio: {
        description: "",
      },
      isLoaded: false,
    };
  },
  created() {
    let context = this;
    var url = new URL(`${process.env.VUE_APP_ROOT_API}`);
    url.pathname += "/get_folio/";
    if (context.username) {
      url.searchParams.append("username", context.username);
    }
    context.showLoader();
    axios
      .get(url)
      .then(function (response) {
        context.hideLoader();
        context.isLoaded = true;
        let data = response.data;
        context.folio = data.folio;
      })
      .catch((error) => {
        context.hideLoader();
        this.$router.push({ name: "Home" });
      });
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.profile-complete {
  text-align: center;
  max-width: 100%;
  h1 {
    font-family: "Satoshi", sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 1.2;
    margin: 0 0 15px;
  }

  h2 {
    font-weight: 400;
    font-size: 15px;
    line-height: 1.2;
    margin: 0 0 25px;
  }
  .portfolio-url {
    margin: 0 auto;
    display: flex;
    width: max-content;
    overflow: hidden;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .input-wrap {
    display: flex;
    align-items: center;
    margin: 0 auto 10px;
    border-radius: 5px;
    background: #2f2f2f;
    padding: 10px 20px;
    line-height: 1.25;
    color: #fff;
    height: 40px;
    font-size: 14px;
    @media (min-width: 768px) {
      margin: 0 auto;
      border-radius: 5px 0 0 5px;
      font-size: 16px;
    }
  }

  .view-btn {
    display: block;
    padding: 0 20px;
    text-align: center;
    outline: 0;
    text-decoration: none;
    margin: 0;
    background: var(--primary-cta-background);
    color: var(--primary-cta-color);
    cursor: pointer;
    border: 1px solid var(--primary-cta-background);
    border-radius: 5px;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    @media (min-width: 768px) {
      border-radius: 0 5px 5px 0;
    }
  }
}

.portfolio-share {
  margin: 80px auto 40px;
  background: #2f2f2f;
  border-radius: 20px;
  color: #fff;
  text-align: center;
  overflow: hidden;
  @media (min-width: 768px) {
    width: 600px;
  }
  .share-content {
    padding: 30px;
  }
  .share-buttons-wrap {
    background: #181818;
    padding: 15px 30px;
  }
  .share-btn {
    display: inline-flex;
    align-items: center;
    background: #f0ff64;
    border-radius: 35px;
    margin: 8px;
    padding: 10px 20px;
    color: #000;
    text-decoration: none;
    font-size: 14px;
    min-height: 40px;
    img {
      display: block;
    }
    span {
      display: block;
      margin-left: 7px;
    }
    &.btn-mail {
      color: #fff;
      background: #df4a41;
    }
    &.btn-fb {
      color: #fff;
      background: #0197f6;
    }
    &.btn-twitter {
      color: #fff;
      background: #3bcbeb;
    }
    &:hover {
      opacity: 0.9;
    }
  }
}
</style>
