<template>
    <ProfileComplete />
</template>
    
<script>
import ProfileComplete from '../components/NewUser/ProfileComplete.vue';

export default {
    name: 'ProfileReady',
    components: {
        ProfileComplete
    }
}
</script>